window.addEventListener("load", function () {
    for(const elem of this.document.querySelectorAll(".menu-checkbox")){
        elem.checked = false;
        elem.addEventListener("change", function () {
            document.body.classList.toggle("mobile-menu-open", elem.checked);
        });
    }

    for(const elem of this.document.querySelectorAll(".sub-menu-dropdown-checkbox")){   
        elem.checked = false;
        elem.addEventListener("change", function () {
            elem.closest(".menu-item").classList.toggle("menu-open", elem.checked);
        });
    };

});
