(function () {
  "use strict";
  document.querySelector("html").classList.remove("no-js");

  const translate = document.querySelector(".goog-te-gadget");
  if (translate) {
    translate.childNodes[1].textContent = " ";
  }

  const notificationCards = document.querySelectorAll(".notification-card");

  for (const notificationCard of notificationCards) {
    const closeNotificationNode = notificationCard.querySelector(".notification-close");
    const hash = notificationCard.dataset.hash;
    const itemKey = `notification-${hash}`;
    const notificationState = window.sessionStorage.getItem(itemKey) ?? "open";

    notificationCard.style.display = notificationState === "closed" ? "none" : "grid";

    closeNotificationNode.addEventListener("click", function () {
      notificationCard.remove();
      window.sessionStorage.setItem(itemKey, "closed");
    });
  }
})();
